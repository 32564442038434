import React from "react";
import { useCompletedItems } from "../../../hooks/use-docusign";
import styles from "./completed-panel.module.css";
import { CompletedItemCard } from "./completed-item-card";
interface IProps {
  authToken: string | undefined;
}

export const CompletedPanel: React.FC<IProps> = (props: IProps) => {
  const { authToken } = props;
  const { data: completedResult } = useCompletedItems(authToken);
  return (
    <div className={styles.notCompletedPanelContainer}>
      <div>
        <span>You have </span>
        <span className={styles.attention}>
          {completedResult?.itemsQueuedForPurge.length ?? 0}
        </span>
        <span>
          {(completedResult?.itemsQueuedForPurge.length ?? 0) === 1
            ? " envelope to scheduled for purge"
            : " envelopes scheduled for purge"}
        </span>
      </div>
      <h1 className={styles.heading}>Scheduled for Purge</h1>
      <div>
        {completedResult?.itemsQueuedForPurge ? (
          <div className={styles.itemsContainer}>
            {completedResult?.itemsQueuedForPurge.map((item) => (
              <CompletedItemCard item={item} key={item.id} />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};
