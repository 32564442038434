import React from "react";
import styles from "./tabs.module.css";
import { TabType } from "../../model/tab-type";

interface IProps {
  onTabSelected: (tabType: TabType) => void;
  selectedTab: TabType;
}

export const Tabs: React.FC<IProps> = (props: IProps) => {
  const { onTabSelected, selectedTab } = props;
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);

  return (
    <div className={styles.tabsContainer}>
      <div className={styles.tabsLeftContainer}>
        <button
          className={`${styles.tab} ${
            selectedTab === "NotCompleted"
              ? styles.selectedTab
              : styles.notSelectedTab
          }`}
          type="button"
          onClick={() => onTabSelected("NotCompleted")}
        >
          Waiting To Sign
        </button>
        <button
          className={`${styles.tab} ${
            selectedTab === "Completed"
              ? styles.selectedTab
              : styles.notSelectedTab
          }`}
          type="button"
          onClick={() => onTabSelected("Completed")}
        >
          Take Backups
        </button>
      </div>
    </div>
  );
};
