import React from "react";
import styles from "./not-completed-panel.module.css";
import { useIncompleteItems } from "../../../hooks/use-docusign";
import { ActionRequiredItem } from "./action-required-item";
import { WaitingOnOthersItem } from "./waiting-on-others-item";

interface IProps {
  authToken: string | undefined;
  name: string | undefined;
}

export const NotCompletedPanel: React.FC<IProps> = (props: IProps) => {
  const { authToken, name } = props;
  const { data: incompleteItemsResult } = useIncompleteItems(authToken);
  return (
    <div className={styles.notCompletedPanelContainer}>
      <h1 className={styles.heading}>Hello {name}</h1>
      <div>
        <span>You have </span>
        <span className={styles.attention}>
          {incompleteItemsResult?.actionRequiredEvents.length ?? 0}
        </span>
        <span>
          {(incompleteItemsResult?.actionRequiredEvents.length ?? 0) === 1
            ? " envelope to sign"
            : " envelopes to sign"}
        </span>
      </div>
      <h1 className={styles.heading}>Action Required</h1>
      <div>
        {incompleteItemsResult?.actionRequiredEvents ? (
          <div className={styles.itemsContainer}>
            {incompleteItemsResult?.actionRequiredEvents.map((item) => (
              <ActionRequiredItem item={item} key={item.id} />
            ))}
          </div>
        ) : null}
      </div>
      <h1 className={styles.heading}>Waiting for Others</h1>
      <div>
        {incompleteItemsResult?.waitingOnOthersEvents ? (
          <div className={styles.itemsContainer}>
            {incompleteItemsResult?.waitingOnOthersEvents.map((item) => (
              <WaitingOnOthersItem item={item} key={item.id} />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};
