import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "@fluentui/react-northstar";
import styles from "./tab.module.css";
import { useTeams } from "../hooks/useTeams";
import * as microsoftTeams from "@microsoft/teams-js";
import jwtDecode from "jwt-decode";
import useStore from "../hooks/use-store";
import { Tabs } from "./docusign/tabs";
import { TabType } from "../model/tab-type";
import { CompletedPanel } from "./docusign/completed/completed-panel";
import { NotCompletedPanel } from "./docusign/not-completed/not-completed-panel";

export const Tab = () => {
  const [{ inTeams, theme }] = useTeams();
  const [name, setName] = React.useState<string>();
  const [token, setToken] = React.useState<string>();

  const appState = useStore();

  const handleTabSelected = (tab: TabType) => {
    appState.setSelectedTab(tab);
  };

  const queryClient = new QueryClient();

  React.useEffect(() => {
    if (inTeams === true) {
      microsoftTeams.authentication.getAuthToken().then(
        (authToken: string) => {
          setToken(authToken);
          const decoded: { [key: string]: any } = jwtDecode(authToken) as {
            [key: string]: any;
          };
          setName(decoded!.name);
        },
        (error: string) => {
          microsoftTeams.app.notifyFailure({
            reason: microsoftTeams.app.FailedReason.AuthFailed,
            message: error,
          });
        }
      );
    }
  }, [inTeams]);

  return (
    <Provider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <div className={styles.docusignTabContainer}>
          <div>
            <Tabs
              onTabSelected={handleTabSelected}
              selectedTab={appState.selectedTab}
            />
          </div>

          <div className={styles.dataPanelContainer}>
            {appState.selectedTab === "NotCompleted" ? (
              <NotCompletedPanel authToken={token} name={name} />
            ) : (
              <CompletedPanel authToken={token} />
            )}
          </div>
        </div>
      </QueryClientProvider>
    </Provider>
  );
};
