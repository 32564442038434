import React from "react";
import { format } from "date-fns";
import { addDays, isBefore, isEqual } from "date-fns";
import { CompletedItem } from "../../../model/completed-item";
import styles from "./completed-item-card.module.css";

type Props = {
  item: CompletedItem;
};

export const CompletedItemCard: React.FC<Props> = (props: Props) => {
  const { item } = props;
  const expDateThreshold = addDays(new Date(), 5);
  return (
    <div key={item.id} className={styles.itemContainer}>
      <p className={styles.emailSubject}>{item.envelopeEmailSubject}</p>
      <div className={styles.dataLine}>
        <p>
          <span className={styles.dataItemHeading}>Sender:</span>
          <span> {item.envelopeSenderUserName}</span>
        </p>
      </div>
      <div className={styles.dataLine}>
        <p>
          <span className={styles.dataItemHeading}>Purge Date:</span>
          <span
            className={
              isBefore(new Date(item.scheduledPurgeDate), expDateThreshold) ||
              isEqual(new Date(item.scheduledPurgeDate), expDateThreshold)
                ? styles.attentionText
                : styles.normalText
            }
          >
            {" "}
            {item.scheduledPurgeDate && item.scheduledPurgeDate.length > 0
              ? format(new Date(item.scheduledPurgeDate), "MMMM dd, yyyy")
              : "N/A"}
          </span>
        </p>
      </div>
    </div>
  );
};
