import create from 'zustand';
import { TabType } from '../model/tab-type';

interface AppState {
  selectedTab: TabType;
  setSelectedTab: (selectedTab: TabType) => void;
}

const useStore = create<AppState>((set, get) => ({
  selectedTab: 'NotCompleted',
  setSelectedTab: (selectedTab: TabType) => {
    set((state) => ({ selectedTab }));
  },
}));

export default useStore;
