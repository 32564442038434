import React from "react";
import { format } from "date-fns";
import { addDays, isBefore, isEqual } from "date-fns";
import { IncompleteItem } from "../../../model/incomplete-item";
import styles from "./action-required-item.module.css";

type Props = {
  item: IncompleteItem;
};

export const ActionRequiredItem: React.FC<Props> = (props: Props) => {
  const { item } = props;
  const expDateThreshold = addDays(new Date(), 5);
  return (
    <div key={item.id} className={styles.itemContainer}>
      <p className={styles.emailSubject}>{item.envelopeEmailSubject}</p>
      <div className={styles.dataLine}>
        <p>
          <span className={styles.dataItemHeading}>Sender:</span>
          <span> {item.envelopeSenderUserName}</span>
        </p>
      </div>
      <div className={styles.dataLine}>
        <p>
          <span className={styles.dataItemHeading}>Sent:</span>
          <span>
            {" "}
            {item.expireDate && item.sentDateTime.length > 0
              ? format(new Date(item.sentDateTime), "MMMM dd, yyyy")
              : "N/A"}
          </span>
        </p>
      </div>
      <div className={styles.dataLine}>
        <p>
          <span className={styles.dataItemHeading}>Expires:</span>
          <span
            className={
              isBefore(new Date(item.expireDate), expDateThreshold) ||
              isEqual(new Date(item.expireDate), expDateThreshold)
                ? styles.attentionText
                : styles.normalText
            }
          >
            {" "}
            {item.expireDate && item.expireDate.length > 0
              ? format(new Date(item.expireDate), "MMMM dd, yyyy")
              : "N/A"}
          </span>
        </p>
      </div>
      <div className={styles.actionLinkContainer}>
        <a
          className={styles.actionLink}
          href={`https://appdemo.docusign.com/documents/details/${item.envelopeId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>Sign in Docusign</span>
        </a>
      </div>
    </div>
  );
};
