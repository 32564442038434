import { useQuery, QueryObserverResult } from "react-query";
import { ICompletedResult } from "../model/completed-item";
import { IIncompleteResult } from "../model/incomplete-item";
import { getIncompleteItems, getCompleteItems } from "../api/docusign-api";

export const useIncompleteItems = (
  authToken: string | undefined
): QueryObserverResult<IIncompleteResult, Error> => {
  return useQuery<IIncompleteResult, Error>(
    ["my-action-required-events"],
    () => getIncompleteItems(authToken),
    {
      useErrorBoundary: true,
      enabled: !!authToken,
      staleTime: Infinity,
      retry: 1,
    }
  );
};

export const useCompletedItems = (
  authToken: string | undefined
): QueryObserverResult<ICompletedResult, Error> => {
  return useQuery<ICompletedResult, Error>(
    ["my-completed-items"],
    () => getCompleteItems(authToken),
    {
      useErrorBoundary: true,
      enabled: !!authToken,
      staleTime: Infinity,
      retry: 1,
    }
  );
};
