import { buildErrorMessage } from "./api-utils";
import { IIncompleteResult } from "../model/incomplete-item";
import { ICompletedResult } from "../model/completed-item";
import { config } from "../config";

export const getIncompleteItems = async (
  accessToken?: string
): Promise<IIncompleteResult> => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers,
  };

  const response = await fetch(
    `${config.apiPrefix}/api/v1/docusign/incomplete`,
    options
  );

  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    const errorMessage = await buildErrorMessage(response);
    return Promise.reject(new Error(errorMessage));
  }
};

export const getCompleteItems = async (
  accessToken?: string
): Promise<ICompletedResult> => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers,
  };

  const response = await fetch(
    `${config.apiPrefix}/api/v1/docusign/complete`,
    options
  );

  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    const errorMessage = await buildErrorMessage(response);
    return Promise.reject(new Error(errorMessage));
  }
};
